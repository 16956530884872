import { FC, useState } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { AppBar, Box } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { colors } from '../../../styles/colors';
import { MobileMenuButton } from './MobileMenuButton';
import { MobileMenu } from './MobileMenu';
import { ProjectLogo } from './ProjectLogo';

export const Navbar: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleIsMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => setIsMenuOpen(false);

  return (
    <StyledAppBar position="fixed">
      <StyledToolbar>
        <Link href="/">
          <a>
            <Box maxWidth={'120px'} maxHeight={'80%'} height={'100%'}>
              <ProjectLogo />
            </Box>
          </a>
        </Link>

        <MobileMenuButton
          isMenuOpen={isMenuOpen}
          toggleIsMenuOpen={toggleIsMenuOpen}
        />
      </StyledToolbar>

      <MobileMenu isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
    </StyledAppBar>
  );
};

export const StyledAppBar = styled(AppBar)`
  height: 50px;
  background: ${colors.brand};

  z-index: 50;
`;

export const StyledToolbar = styled(Toolbar)`
  min-height: 100%;
  justify-content: space-between;
`;

/*
 height: ${size.inAppNavBarHeight};
 */
