import React, { FC } from 'react';
import { NavigationRegisterPrompt } from './NavigationRegisterPrompt';
import { Box, Button, Link as MuiLink, Typography } from '@mui/material';
import { Icon } from '../../../components/Icon/Icon';
import Link from 'next/link';
import { LogoutIcon } from '../../../components/Icon/Icons/Logout.icon';
import { Trans } from '@lingui/macro';
import styled, { css } from 'styled-components';
import { ButtonProps } from '@mui/material/Button/Button';
import { logOut } from '../../../utils/authHelpers';
import { Routes } from '../../../enums/routes';
import { getNavigationStructure } from './getNavigationStructure';
import { useUserData } from '../../../hooks/useUserData';
import { useRouter } from 'next/router';
import { useWhiteLabelContext } from '../../WhitelabeProvider';

export const Navigation: FC = () => {
  const router = useRouter();
  const { isAdmin, user, loading } = useUserData();

  const handleLogout = async () => {
    await logOut(() => router.push(Routes.Login));
  };

  const showRegisterPrompt =
    !user &&
    !loading &&
    ![
      Routes.Login,
      Routes.Registration,
      Routes.AuthRequestPasswordReset,
    ].includes(router.pathname as Routes);

  const { communityLink } = useWhiteLabelContext();

  const navigationStructure = getNavigationStructure({
    user,
    isAdmin,
    communityLink,
  });

  return (
    <>
      {showRegisterPrompt && <NavigationRegisterPrompt />}

      {user?.profile && user?.onboardingData && (
        <>
          {navigationStructure.map((section) => (
            <Box marginBottom={7} key={section.header}>
              <Box marginY={2}>
                <Typography variant="overline">{section.header}</Typography>
              </Box>

              {section.links.map(
                ({ href, label, linkIcon, hide, external }) => {
                  if (hide) {
                    return null;
                  }

                  if (external) {
                    return (
                      <MuiLink
                        href={href}
                        key={label}
                        underline="none"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <StyledButton
                          fullWidth
                          href={href}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <Icon Component={linkIcon} />
                          <Typography>{label}</Typography>
                        </StyledButton>
                      </MuiLink>
                    );
                  }

                  return (
                    <Link href={href} key={label}>
                      <StyledButton
                        fullWidth
                        href={href}
                        $isSelected={href === router.pathname}
                      >
                        <Icon Component={linkIcon} />
                        <Typography>{label}</Typography>
                      </StyledButton>
                    </Link>
                  );
                }
              )}
            </Box>
          ))}

          <StyledButton fullWidth onClick={handleLogout}>
            <Icon Component={LogoutIcon} />
            <Typography align="left">
              <Trans>Odhlásit se</Trans>
            </Typography>
          </StyledButton>
        </>
      )}
    </>
  );
};

const StyledButton = styled(Button)<ButtonProps & { $isSelected?: boolean }>`
  ${(props) =>
    props.$isSelected &&
    css`
      background-color: rgba(255, 255, 255, 0.05);
    `}

  display: grid;
  color: white;
  grid-template-columns: auto 1fr;
  grid-gap: 16px;
  cursor: pointer;
  align-items: center;
  margin-bottom: 8px;
`;
